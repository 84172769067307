.App {
  text-align: center;
  position: relative;
  min-height: calc(100vh - 80px);
}

.emotions {
  width: 90%;
  margin-top: 80px;
  margin-left: 5%;
}

.add {
  width: 90%;
  min-height: 200px;
  margin-top: 10px;
  margin-left: 5%;
}

.no-emotions-container {
  padding-top: 20px;
  font-size: 20px;
  color: #33495D;
}

@media screen and (min-width:600px) {
  .emotions {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .add {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

.buttons {
  position: fixed;
  right: 0;
  bottom: 60px;
  padding: 20px;
  z-index: 2;
  border-radius: 10px 0 0 10px;
  border: 1px solid #b0c9d4;
  display: flex;
  flex-direction: column;
  background-color: #d5e1e8;
}

.iconButton {
  color: #35495A;
}

.textButton {
  color: #35495A;
}

.iconStyle {
  color: #35495A;
}

.text-field {
  color: #35495A;
}

.chips-container {
  display: flex;
  flex-direction: column;
}

.link-chip {
  margin: 10px;
}