.add-container {
    width: 100%;
    height: 145px;
    border-radius: 10px;
    background-color: #d5e1e8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.add-title {
    font-size: 20px;
    color: #33495D;
}