.modal-background {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.modal-container {
    min-height: 100px;
    width: 80%;
    max-width: 400px;
    padding: 20px;
    border-radius: 10px;
    background-color: #f4f4f4;
}

.modal-close {
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.modal-close > button {
    padding: 0px;

}

.modal-title {
    margin-top: 0px;
    font-size: 25px;
    color: #33495D;
}

.modal-text {
    width: 80%;
    margin-left: 10%;
    text-align: justify;
    color: #33495D;
}

.modal-form-container {
    width: 80%;
    margin-left: 10%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #33495D;
    overflow: hidden;
}

.modal-form-container > div > div > input{
    color: #33495D;
}

.modal-form-container > div > div::after{
    border-bottom: 2px solid #33495D;
}

.modal-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.modal-buttons > label {
    border: none;
}

.modal-buttons > label:focus {
    border: none;
}

.modal-buttons > label:hover {
    border: none;
}
