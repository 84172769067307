.emotion-container{
    width: 100%;
    margin-top: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background-color: #d5e1e8;
}

.emotion-selected {
   background-color: #b0c9d4; 
}

.emotion-header {
    width: calc(100% - 20px);
    flex: 1;
    display: flex;
    flex-direction: row;
}

.emotion-name {
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    color: #33495D;
}

.emotion-icons {
    padding-top: 20px;
    padding-bottom: 20px;
    flex: 1;
    text-align: left;
    margin-left: 10px;
}

.emotion-expand {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: right;
}

.emotion-content-container {
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #33495D;
    overflow: hidden;
}

.emotion-content-container > div > div > textarea{
    color: #33495D;
}

.emotion-content-container > div > div::after{
    border-bottom: 2px solid #33495D;
}

.emotion-content-container-no-expanded {
    max-height: 0;
    transition: max-height 1s 0s ease-out;
}

.emotion-content-container-expanded {
    max-height: 1000px;
    transition: max-height 1s 0s ease-in;
}

.emotion-content-title {
    color: #33495D;
    font-weight: bold;
}

.emotion-actions {
    margin-bottom: 10px;
}