.main-footer {
    width: 100%;
    height: 42px;
    background-color: #d5e1e8;
    border-top: 1px solid #b0c9d4;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-footer-text {
    color: #33495D;
}

.main-footer-text > a {
    color: #33495D;
    text-decoration: none;
}
