.header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #d5e1e8;
    border-bottom: 1px solid #b0c9d4;
}

.header > h1 {
    margin: 0px;
    color: #35495A;
}